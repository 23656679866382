<template>
    <div class="container">
        <div class="success-icon">
            <van-icon color='#00DE93' size="80px" name="checked" />
            <div>付款成功</div>
        </div>
        <van-cell v-for="(item,index) in list" :key='index' :title="item.label" :value="item.value" size="large" />
        
        <loadings :loading='loading' />
    </div>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'
import Loadings from '@/components/loading'
    export default {
        name:'paymentSuccess',
        data() {
            return {
                loading: false,
                list:[
                    {label:'付款校区',value:''},
                    {label:'课程顾问',value:''},
                    {label:'交易订单',value:''},
                ],
                activeBar:-1
            }
        },
        computed: {
        },
        components: {
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            loadings:Loadings,
        },
        created () {
            this.getInfo()
        },
        methods: {
            getInfo(){
                this.loading = true
                let params = {
                    apply_id:this.$route.query.apply_id
                }
                this.$api.crm_contract_detail(params)
                .then(res => {
                    this.list[0].value = res.data.studio_name
                    this.list[1].value = res.data.course_consultant_name
                    this.list[2].value = res.data.out_trade_no
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    console.error(err)
                })
            },
            // handleRouterChange(e){
            //     if(e === 0){
            //         this.$router.push({path:'/home'})
            //     }else if(e === 1){
            //         this.$router.push({path:'/recruit',query:{appid:localStorage.getItem('appid')}})
            //     }else if(e === 2){
            //         this.$router.push({path:'/schedule'})
            //     }else if(e === 3){
            //         this.$router.push({path:'/mall'})
            //     }else{
            //         let num = localStorage.getItem('profile_employee')
            //         if(num == 1){
            //             this.$router.push({path:'/profile'})
            //         }else{
            //             this.$router.push({path:'/user'})
            //         }
            //     }
            // },
        },
        
    }
</script>

<style lang="less" scoped>
    .success-icon{
        text-align: center;
        background-color: #fff;
        padding: 40px 0;
        div{
            font-size: 16px;
            font-weight: bold;
            color: #00DE93;
        }
    }
    .report-btn{
        width: 100%;
        height: 50px;
        background-color: #00DE93;
        position: fixed;
        bottom: 0;
        text-align: center;
        line-height: 50px;
        color: #fff;
    }
</style>